import { ChangeEvent, FC, useEffect } from 'react';

import {
  Col,
  Divider,
  Flex,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Typography,
} from 'antd';
import { FormInstance } from 'antd/lib';
import { useTheme } from 'styled-components';

import IconInfo from '@/assets/icons/icon-info.svg?react';
import RTooltip from '@/components/RTooltip';
import { PlantForm } from '@/types/plant';
import {
  formatInputNumber,
  normalizePositiveIntegerValuesInput,
  normalizePositiveNumericValuesInput,
  removeCommas,
} from '@/utils';
const { Option } = Select;

type Props = {
  disabled?: boolean;
  form: FormInstance<PlantForm>;
  initialValues?: PlantForm;
};

const FinancialMetrics: FC<Props> = ({
  disabled = false,
  form,
  initialValues,
}) => {
  const theme = useTheme();
  const initialYearCount = form.getFieldValue('investmentLag');
  const initialPreopCapexDistribution = form.getFieldValue(
    'preopCapexDistribution'
  );
  const yearCount = Form.useWatch('investmentLag', form) || initialYearCount;
  const preopCapexDistribution =
    Form.useWatch('preopCapexDistribution', form) ||
    initialPreopCapexDistribution;

  const loanFinancing = Form.useWatch('loanFinancing', form) || 0;
  const loanRepaymentPeriod = Form.useWatch('loanRepaymentPeriod', form);

  const handleLoanFinancingChange = (e: RadioChangeEvent) => {
    const value = e.target.value;
    form.setFieldValue('loanFinancing', value);

    if (value === 0) {
      form.setFieldsValue({
        loanRepaymentPeriod: 1,
        yearlyInterestExpense: [0],
        loanFundedCapexRatio: 0,
      });
    }
  };

  const handleLoanRepaymentPeriodChange = () => {
    const postOperationYears =
      form.getFieldValue('yearlyInterestExpense') || [];
    const updatedPostOperationYears = postOperationYears.map(() => null);
    form.setFieldsValue({ yearlyInterestExpense: updatedPostOperationYears });
  };

  useEffect(() => {
    if (form) {
      if (initialValues) {
        const initialYearCount = initialValues.investmentLag;
        if (yearCount != initialYearCount) {
          const year = Number(yearCount) + 1;
          form.setFieldValue('preopCapexDistribution', Array(year).fill(''));
        }
        if (yearCount == initialYearCount) {
          form.setFieldValue(
            'preopCapexDistribution',
            initialValues.preopCapexDistribution
          );
        }
      } else {
        const year = Number(yearCount) + 1;
        if (form.isFieldTouched('investmentLag')) {
          form.setFieldValue('preopCapexDistribution', Array(year).fill(''));
        }
      }
    }
  }, [form, initialValues, yearCount]);

  useEffect(() => {
    if (form.isFieldTouched('investmentLag')) {
      const yearCount =
        parseInt(form.getFieldValue('investmentLag') || 0, 10) + 1;

      if (yearCount === 1) {
        form.setFieldsValue({
          preopCapexDistribution: Array(yearCount).fill(100),
        });
      }
    }
  }, [form, yearCount]);

  useEffect(() => {
    if (form && preopCapexDistribution) {
      const validateTotal = () => {
        // Calculate the total value
        const total = preopCapexDistribution.reduce(
          (sum: number, item: string) => {
            const value = parseFloat(item) || 0;
            return sum + value;
          },
          0
        );

        if (total !== 100) {
          // Set form errors if the total is not exactly 100
          form.setFields(
            preopCapexDistribution.map((_: unknown, index: number) => ({
              name: [`preopCapexDistribution`, index],
              errors: [
                total > 100
                  ? 'The total value of Pre-Operation Years cannot exceed 100.'
                  : 'The total value of Pre-Operation Years must be exactly 100.',
              ],
            }))
          );
        } else {
          // Clear errors if total is exactly 100
          form.setFields(
            preopCapexDistribution.map((_: unknown, index: number) => ({
              name: [`preopCapexDistribution`, index],
              errors: [],
            }))
          );
        }
      };
      validateTotal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preopCapexDistribution]);

  const handleNumberChange =
    (field: keyof PlantForm) => (event: ChangeEvent<HTMLInputElement>) => {
      const rawValue = event.target.value.replace(/[^0-9.]/g, '');
      const formatted = formatInputNumber(rawValue);
      form?.setFieldValue(field, formatted);
    };

  const handleNumberBlur = (field: keyof PlantForm) => () => {
    const value = form?.getFieldValue(field) || '';
    form?.setFieldValue(field, removeCommas(value));
  };

  const selectAfter = (
    <Form.Item<PlantForm> style={{ margin: '0px' }} name="capexUnit">
      <Select disabled={disabled} style={{ width: 90 }}>
        <Option value="dollars">$</Option>
        <Option value="dollars_per_mw">$/MW</Option>
      </Select>
    </Form.Item>
  );

  return (
    <Form.Item<PlantForm>
      wrapperCol={{ span: 24 }}
      className="p-16 pb-40"
      style={{
        border: '0.5px solid',
        borderRadius: '10px',
        borderColor: theme.colors.grayLightAccent,
      }}
    >
      <Row>
        <Col className="pr-8" span={12}>
          <Form.Item<PlantForm>
            name="investmentLag"
            label={
              <Flex gap={8} align="center">
                <Typography.Text className="fs-14-regular text-black-color">
                  Duration of Pre-Operational Phase
                </Typography.Text>
                <RTooltip
                  title="Duration of Pre-Operational Phase"
                  description="This input indicates the number of years the Power Plant is under construction and cannot operate. It represents the time before the plant starts being productive."
                >
                  <IconInfo />
                </RTooltip>
              </Flex>
            }
            normalize={normalizePositiveIntegerValuesInput}
            rules={[
              {
                required: true,
                message: 'Duration of Pre-Operational Phase is required',
              },
              {
                validator(_, value) {
                  const minValue = parseFloat(value);
                  if (isNaN(minValue) || minValue < 0) {
                    return Promise.reject(
                      new Error('Value must be between 0 and 100.')
                    );
                  } else if (minValue > 100) {
                    form?.setFieldsValue({ investmentLag: 100 });
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              type="number"
              disabled={disabled}
              placeholder="Duration of Pre-Operational Phase"
              addonAfter="Year"
            />
          </Form.Item>
          {yearCount > 0 && (
            <Col className="mb-24">
              <Divider
                dashed
                style={{
                  marginBottom: '-12px',
                  borderColor: theme.colors.grayLightAccent,
                }}
                orientation="left"
              >
                <Typography.Text>CAPEX Distribution by Year</Typography.Text>
              </Divider>
              {/* MOVE THIS INLINE STYLES TO STYLED COMPONENTS */}
              <Row className="preop-capex">
                <Form.List
                  name="preopCapexDistribution"
                  initialValue={Array(yearCount).fill({})}
                >
                  {(subFields) => {
                    const isOperationInitiation = 0;
                    const reversedKeys = subFields
                      .map((item) => item.key)
                      .reverse();

                    const updatedSubFields = subFields.map((item, index) => ({
                      ...item,
                      key: reversedKeys[index],
                    }));

                    return (
                      <Row gutter={[12, 12]}>
                        {updatedSubFields.map((subField) => (
                          <Col span={12}>
                            <Form.Item
                              key={`preOperationYear${subField.key}`}
                              name={subField.name}
                              label={
                                <Flex gap={8} align="center">
                                  <Typography.Text className="fs-14-regular text-black-color">
                                    {subField.key === isOperationInitiation
                                      ? 'Operation Initiation Year'
                                      : `Pre-Operation Year ${subField.key}`}
                                  </Typography.Text>
                                  <RTooltip
                                    title={
                                      subField.key === isOperationInitiation
                                        ? 'Operation Initiation Year'
                                        : `Pre-Operation Year ${subField.key}`
                                    }
                                    description={
                                      subField.key === isOperationInitiation
                                        ? 'The year in which the storage system begins operating'
                                        : `${subField.key} Year Before Operation Starts`
                                    }
                                  >
                                    <IconInfo />
                                  </RTooltip>
                                </Flex>
                              }
                              rules={[
                                {
                                  required: true,
                                  message: `Pre-Operation Year ${subField.key} is required`,
                                },
                                {
                                  validator(_, value) {
                                    const minValue = parseFloat(value);
                                    if (isNaN(minValue) || minValue < 0) {
                                      return Promise.reject(
                                        new Error(
                                          'Value must be between 0 and 100.'
                                        )
                                      );
                                    } else if (minValue > 100) {
                                      return Promise.reject(
                                        new Error(
                                          'Value must be between 0 and 100.'
                                        )
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            >
                              <Input
                                disabled={disabled}
                                placeholder={`Enter Pre-Operation Year ${subField.key}`}
                                addonAfter="%"
                              />
                            </Form.Item>
                          </Col>
                        ))}
                      </Row>
                    );
                  }}
                </Form.List>
              </Row>
            </Col>
          )}
        </Col>
        <Col className="pl-8" span={12}>
          <Form.Item<PlantForm>
            name="lifespan"
            label={
              <Flex gap={8} align="center">
                <Typography.Text className="fs-14-regular text-black-color">
                  Operational Life Span
                </Typography.Text>
                <RTooltip
                  title="Operational Life Span"
                  description="After this period, the Power Plant is no longer functional and must be decommissioned."
                >
                  <IconInfo />
                </RTooltip>
              </Flex>
            }
            rules={[
              { required: true, message: 'Operational Life Span is required' },
            ]}
            normalize={normalizePositiveIntegerValuesInput}
          >
            <Input
              disabled={disabled}
              placeholder="Operational Life Span"
              addonAfter="Year"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col className="pr-8" span={12}>
          <Form.Item<PlantForm>
            name="depreciationYears"
            label="Depreciation Years"
            normalize={normalizePositiveIntegerValuesInput}
            rules={[
              { required: true, message: 'Depreciation Years is required!' },
            ]}
          >
            <Input
              disabled={disabled}
              placeholder="Depreciation Years"
              addonAfter="Year"
            />
          </Form.Item>
        </Col>
        <Col className="pl-8" span={12}>
          <Form.Item<PlantForm>
            name="loanFinancing"
            label="Financing Method"
            rules={[
              { required: true, message: 'Financing Method is required!' },
            ]}
          >
            <Radio.Group
              onChange={handleLoanFinancingChange}
              buttonStyle="solid"
              disabled={disabled}
            >
              <Radio.Button value={0}>Full Equity</Radio.Button>
              <Radio.Button value={1}>Loan Financing</Radio.Button>
            </Radio.Group>
          </Form.Item>
          {loanFinancing === 1 && (
            <Row>
              <Col span={24}>
                <Form.Item<PlantForm>
                  name="loanFundedCapexRatio"
                  label="Loan to Capex Ratio"
                  normalize={normalizePositiveNumericValuesInput}
                  rules={[
                    {
                      required: true,
                      message: 'Loan to Capex Ratio is required!',
                    },
                    {
                      validator(_, value) {
                        const minValue = parseFloat(value);
                        if (isNaN(minValue) || minValue < 0) {
                          return Promise.reject(
                            new Error('Value must be between 0 and 100.')
                          );
                        } else if (minValue > 100) {
                          form?.setFieldsValue({ loanFundedCapexRatio: 100 });
                          return Promise.resolve();
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Loan-to-CAPEX Ratio (%)"
                    addonAfter="%"
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item<PlantForm>
                  name="loanRepaymentPeriod"
                  label="Loan Repayment Period"
                  normalize={normalizePositiveIntegerValuesInput}
                  rules={[
                    {
                      required: true,
                      message: 'Loan Repayment Period is required!',
                    },
                    {
                      validator(_, value) {
                        const minValue = parseFloat(value);
                        if (isNaN(minValue) || minValue < 1) {
                          return Promise.reject(
                            new Error('Value must be between 1 and 100.')
                          );
                        } else if (minValue > 100) {
                          form?.setFieldsValue({ loanRepaymentPeriod: 100 });
                          return Promise.resolve();
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Loan Repayment Period (Years)"
                    onChange={handleLoanRepaymentPeriodChange}
                    addonAfter="Year"
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
              {loanRepaymentPeriod !== undefined &&
                loanRepaymentPeriod !== null &&
                loanRepaymentPeriod !== 0 && (
                  <Col span={24} className="mb-24">
                    <Divider
                      dashed
                      style={{
                        marginBottom: '-12px',
                        marginTop: '-12px',
                        marginLeft: '-6px',
                        borderColor: theme.colors.grayLightAccent,
                      }}
                      orientation="left"
                    >
                      <Typography.Text>Annual Interest Expense</Typography.Text>
                    </Divider>
                    <Row gutter={12} className="w-100 preop-capex">
                      {Array.from(
                        { length: loanRepaymentPeriod },
                        (_, index) => (
                          <Col span={12} key={index}>
                            <Form.Item<PlantForm>
                              name={['yearlyInterestExpense', index]}
                              label={`Post Operation Year ${index + 1}`}
                              normalize={normalizePositiveNumericValuesInput}
                              rules={[
                                {
                                  required: true,
                                  message: `Interest Expense for Year ${index + 1} is required!`,
                                },
                              ]}
                            >
                              <Input
                                placeholder={`Interest Expense for Year ${index + 1}`}
                                addonAfter="$"
                                disabled={disabled}
                              />
                            </Form.Item>
                          </Col>
                        )
                      )}
                    </Row>
                  </Col>
                )}
            </Row>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="pr-8" span={12}>
          <Form.Item<PlantForm>
            name="capexValue"
            label="Total Power Plant CAPEX"
            normalize={normalizePositiveNumericValuesInput}
            rules={[
              {
                required: true,
                message: 'Total Power Plant Capex is required',
              },
            ]}
          >
            <Input
              disabled={disabled}
              placeholder="Total Power Plant Capital Expenditure"
              addonAfter={selectAfter}
              onBlur={handleNumberBlur('capexValue')}
              onChange={handleNumberChange('capexValue')}
            />
          </Form.Item>
        </Col>
        <Col className="pl-8" span={12}>
          <Form.Item<PlantForm>
            name="fixedOpex"
            label="OPEX per Year"
            rules={[{ required: true, message: 'OPEX per Year is required' }]}
            normalize={normalizePositiveNumericValuesInput}
          >
            <Input
              disabled={disabled}
              placeholder="Operational Expenditure per Year"
              addonAfter="$"
              onBlur={handleNumberBlur('fixedOpex')}
              onChange={handleNumberChange('fixedOpex')}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default FinancialMetrics;
